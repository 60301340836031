<template>
  <div class="mx-5 my-5">
    <v-row>
      <v-col md="12">
        <v-row>
          <v-col v-if="!isNew && wearhouse == 2 && type == 'ORDER' && approve" md="2"
            @click="$router.push(`/delivery/${$route.params.id}?deliveried=${deliveried}`)">
            <h4 v-if="(deliveried == 'done')" style="color:rgb(22, 170, 22); cursor: pointer;">
              <v-icon style="color:rgb(22, 170, 22);">mdi-truck-fast-outline</v-icon> Delivery: Complete
            </h4>
            <h3 v-else style="color:rgb(45, 45, 255); cursor: pointer;"><v-icon
                style="color:rgb(45, 45, 255)">mdi-truck-fast-outline</v-icon> Delivery: {{ delivery_note_count }} </h3>
          </v-col>
          <v-col v-if="!isNew && wearhouse == 2 && type == 'ORDER' && approve" md="8"
            @click="$router.push(`/receipt/${$route.params.id}?invoice_status=${invoice_status}`)">
            <h3 style=" cursor: pointer;"> <v-icon style="color:gray">mdi-invoice-text-edit-outline</v-icon> Invoices: {{
              invoice_count }} </h3>
          </v-col>
          <v-col v-else md="10"></v-col>
          <v-col v-if="!isNew && wearhouse == 2 && type == 'ORDER' && returnAbility('workflow:approve')" md="1"
            @click="convertToApprove" :loading="submittingApprove">
            <h3 v-if="(approve == true)" style="color:rgb(22, 170, 22); cursor: pointer;">
              {{ "Approved" }}</h3>
            <h3 v-else style=" cursor: pointer;">
              {{ "Approve" }}</h3>
          </v-col>
          <v-col v-else md="1"></v-col>
          <v-col v-if="!isNew && wearhouse == 2 && returnAbility('workflow:order:confirm')" md="1"
            @click="convertInvoice">
            <h3 v-if="(type == 'REQUEST')" style="color:rgb(22, 170, 22); cursor: pointer;">
              {{ "Confirm" }}</h3>
            <h3 v-else style="color:red; cursor: pointer;">
              {{ "Cancel" }}</h3>
          </v-col>

        </v-row>
      </v-col>
      <v-col md="12" class="d-flex" style="justify-content: space-between; align-items: center">
        <v-row>
          <h2 v-if="type == 'ORDER'">{{ (invoice_type == "SALES" || invoice_type == "SALES_REFUNDS") ? "SO-" : "PO-" }} {{
            invoiceTypeLabel }}</h2>
          <h2 v-if="type == 'REQUEST'">{{ (invoice_type == "SALES" || invoice_type == "SALES_REFUNDS") ? "SR-" : "PR-" }}
            {{ invoiceTypeLabel }}</h2>
        </v-row>
        <v-switch @change="toggleInvoiceLock" v-if="!isNew && wearhouse == 2" v-model="is_locked" color="green"
          :label="$t('Invoice Lock')" :dark="$store.state.isDarkMode"></v-switch>
      </v-col>
      <v-col md="3" class="py-0">
        <AutoCompleteField :label="$t('Client Name')" outlined dense v-model="invoice.client_account_id"
          endPoint="/accounting/account/auto-complete" returnObject clearable />
      </v-col>
      <v-col md="3" class="py-3" :readonly="type == 'ORDER'">
        <v-menu ref="menu1" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
          max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :label="wearhouse == 1 ? $t('date') : $t('Request Date')" outlined dense persistent-hint
              prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" v-model="invoiceDateRequest"></v-text-field>
          </template>
          <v-date-picker v-model="invoiceDateRequest" no-title @input="dateMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="3" class="py-3">
        <v-menu ref="timeMenuRef" v-model="timeMenu" :close-on-content-click="false" :nudge-right="40"
          :return-value.sync="invoiceTimeRequest" transition="scale-transition" offset-y max-width="290px"
          min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="invoiceTimeRequest" :label="wearhouse == 1 ? $t('Time') : $t('Request Time')"
              prepend-icon="mdi-clock-time-four-outline" readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="timeMenu" v-model="invoiceTimeRequest" full-width
            @click:minute="$refs.timeMenuRef.save(invoiceTimeRequest)"></v-time-picker>
        </v-menu>
      </v-col>
      <v-col md="3" class="py-3">
        <v-text-field :label="wearhouse == 1 ? $t('Receiving/Delivery Number') : $t('Request Number') " outlined dense
          v-model="invoice.request_serial"></v-text-field>
      </v-col>
      <v-col v-if="wearhouse == 2" md="3" class="py-0">
        <AutoCompleteField :label="$t('cost center')" v-model="invoice.cost_center_id"
          endPoint="/accounting/cost-center/auto-complete" outlined dense returnObject />
      </v-col>
      <v-col v-if="type == 'ORDER'" md="3" class="py-3">
        <v-menu ref="menu1" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
          max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :label="$t('Order Date')" outlined dense persistent-hint prepend-icon="mdi-calendar" v-bind="attrs"
              v-on="on" v-model="invoiceDateOrder"></v-text-field>
          </template>
          <v-date-picker v-model="invoiceDateOrder" no-title @input="dateMenu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col v-if="type == 'ORDER'" md="3" class="py-3">
        <v-menu ref="timeMenuRef" v-model="timeMenu" :close-on-content-click="false" :nudge-right="40"
          :return-value.sync="invoiceTimeOrder" transition="scale-transition" offset-y max-width="290px"
          min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="invoiceTimeOrder" :label="$t('Order Time')" prepend-icon="mdi-clock-time-four-outline"
              readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-time-picker v-if="timeMenu" v-model="invoiceTimeOrder" full-width
            @click:minute="$refs.timeMenuRef.save(invoiceTimeRequest)"></v-time-picker>
        </v-menu>
      </v-col>
      <v-col v-if="type == 'ORDER'" md="3" class="py-3">
        <v-text-field style="font-weight: bold; font-size: larger;" :label="$t('Order Number')" outlined dense
          v-model="invoice.order_serial"></v-text-field>
      </v-col>
      <v-col v-if="wearhouse == 2" md="3" class="py-3"
        style="display: flex; justify-content: center; align-items: flex-start">
        <v-radio-group :dark="$store.state.isDarkMode" row v-model="invoice.discount_type" class="mt-0">
          <template v-slot:label><div style="font-size: 14px;">{{ $t('Discount') }}</div></template>
          <v-radio value="PERCENTAGE"><template v-slot:label><div style="font-size: 14px;">{{ $t('Percent') }}</div></template></v-radio>
          <v-radio value="FIXED"><template v-slot:label><div style="font-size: 14px;">{{ $t('Value') }}</div></template></v-radio>
        </v-radio-group>
      </v-col>
      <v-col v-if="wearhouse == 2" md="3" class="py-3">
        <v-text-field :disabled="!invoice.discount_type" :label="$t('Discount')" :dark="$store.state.isDarkMode" type="number" outlined dense
          v-model="invoice.discount_value"></v-text-field>
      </v-col>
      <v-col md="3" class="py-0">
        <AutoCompleteField :label="$t('Inventory')" outlined dense v-model="invoice.inventory_id" no-filter
          endPoint="/inventories/auto-complete" returnObject clearable @input="matchMaterialInventoriesHandler" />
      </v-col>
      <v-col v-if="wearhouse == 2 && invoice.table_view.length && invoice.table_view[14].value" md="3" class="py-3">
        <v-autocomplete :label="$t('Tax Code')" outlined dense color="#757575" item-text="code" item-value="id"
          :items="taxes" v-model="invoice.tax_id" clearable @input="matchMaterialTax_IdHandler"></v-autocomplete>
      </v-col>
      <v-col md="3" class="py-0">
        <AutoCompleteField :label="$t('Tax Account')" outlined dense v-model="invoice.tax_account_id"
          endPoint="/accounting/account/auto-complete" returnObject clearable @input="matchMaterialTax_AccHandler"/>
      </v-col>
      <v-col       v-if="wearhouse == 2 && invoice.table_view.length && invoice.table_view[16].value" md="3" class="py-3">
        <v-text-field :label="$t('Tax Percent')" v-model="invoice.tax_percent" type="number" outlined @input="matchMaterialTax_PercentHandler"
          dense></v-text-field>
      </v-col>
      <v-col v-if="wearhouse == 2" md="3" class="py-0">
        <AutoCompleteField :label="$t('Discount Account')" outlined dense v-model="invoice.discount_account_id"
          endPoint="/accounting/account/auto-complete" returnObject clearable />
      </v-col>
      <v-col md="6" class="py-3">
        <v-text-field :label="$t('notes')" v-model="invoice.notes" outlined dense></v-text-field>
      </v-col>
      <v-col v-if="wearhouse == 2" md="12">
        <v-row>
          <v-col md="3" class="py-0">
            <v-autocomplete :label="$t('Payment Method')" :dark="$store.state.isDarkMode" outlined dense color="#757575" item-text="label" item-value="value"
              no-filter :items="paymentMethods" v-model="invoice.payment_method"></v-autocomplete>
          </v-col>
          <v-card v-if="invoice.payment_method == 'INSTALLMENT'" md="12" class="my-5 mx-5">
            <v-card-title class="d-flex justify-center">
              <h2>
                {{ $t('Distribution Installments') }}
              </h2>
            </v-card-title>
            <v-card-text>
              <v-container id="buffet-table" fluid tag="section">
                <v-simple-table :dark="$store.state.isDarkMode" style=" width: max-content;">
                  <tr>
                    <th id="idAutoComp">{{ $t('due-date') }}</th>
                    <th id="idAutoComp">{{ $t('hr.loan.trans-value') }}</th>
                    <th id="idAutoComp">{{ $t('note') }}</th>
                    <th></th>
                  </tr>
                  <v-fade-transition mode="out-in" :group="true" tag="tbody">
                    <tr v-for="(el, ix) in installments" :key="ix">
                      <td>
                        <v-text-field full-width dense hide-details type="date" flat color="#757575" v-model="el.date"
                          @keydown.enter="ToNextInput"></v-text-field>
                      </td>
                      <td>
                        <v-text-field full-width dense hide-details type="number" flat color="#757575" v-model="el.amount"
                          @keydown.enter="ToNextInput"></v-text-field>
                      </td>
                      <td>
                        <v-text-field @keydown.enter="addNewInstallment" full-width type="text" dense hide-details flat
                          color="#757575" v-model="el.note"></v-text-field>
                      </td>
                      <td>
                        <v-icon @click="deleteRowI(ix)" color="red"
                          style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>
                      </td>
                    </tr>
                  </v-fade-transition>
                </v-simple-table>
              </v-container>
            </v-card-text>
          </v-card>
          <v-col md="3" class="py-0" v-if="invoice.payment_method == 'CASH'">
            <AutoCompleteField :label="$t('hr.loan.cash')" :cash="1" v-model="invoice.cash_account_id"
              endPoint="/accounting/account/auto-complete" outlined dense returnObject />
          </v-col>
          <v-col md="3" class="py-0" v-if="invoice.payment_method == 'CASH'">
            <v-text-field :dark="$store.state.isDarkMode" :label="$t('Paid Value')" v-model.number="invoice.paid_amount" outlined type="number"
              dense></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-card style="padding: 1%; ">
        <v-container id="buffet-table" fluid tag="section">
          <v-simple-table :dark="$store.state.isDarkMode" style=" width: max-content;">
            <tr>
              <th v-if="wearhouse == 1 || invoice.table_view[0].value" style="padding-left: 100px;  padding-right: 100px;">{{ $t('Material') }}</th>
              <th v-if="wearhouse == 1 || invoice.table_view[1].value" id="idAutoComp">{{ $t('Type') }}</th>
              <th v-if="wearhouse == 1 || invoice.table_view[2].value" id="idPrice">{{ $t('quantity') }}</th>
              <th v-if="type == 'ORDER' || wearhouse == 1" id="idPrice">{{ $t('Delivered Materials') }}</th>
              <th v-if="wearhouse == 1" id="idPrice">{{ $t('Receiving/Delivery') }}</th>
              <th v-if="wearhouse == 1 || invoice.table_view[3].value">{{ $t('Unit') }}</th>
              <th v-if="wearhouse == 2 && invoice.table_view[4].value" id="idPrice">{{ $t('price') }}</th>
              <th v-if="wearhouse == 2 && invoice.table_view[5].value" id="idPrice">{{ $t('sum') }}</th>
              <th v-if="wearhouse == 2 && invoice.table_view[6].value" id="idPrice">{{ $t('Discount') }}</th>
              <th v-if="wearhouse == 2 && invoice.table_view[7].value" id="idPrice">{{ $t('add') }}</th>
              <th v-if="wearhouse == 2 && invoice.table_view[8].value" id="idPrice">{{ $t('Net') }}</th>
              <th v-if="wearhouse == 2 && invoice.table_view[14].value" id="idAutoComp">{{ $t('Tax Code') }}</th>
              <th v-if="wearhouse == 2 && invoice.table_view[16].value" id="idPrice">{{ $t('Tax Percent') }}</th>
              <th v-if="wearhouse == 2 && invoice.table_view[17].value" id="idPrice">{{ $t('Included') }}</th>
              <th v-if="wearhouse == 2 && invoice.table_view[18].value" id="idPrice">{{ $t('Tax Value') }}</th>
              <th v-if="wearhouse == 2 && invoice.table_view[15].value" id="idAutoComp">{{ $t('Tax Account') }}</th>
              <th v-if="wearhouse == 2 && invoice.table_view[9].value" id="idAutoComp">{{ $t('Inventory') }}</th>
              <th v-if="wearhouse == 2 && invoice.table_view[10].value" id="idAutoComp">{{ $t('Corresponding Account') }}</th>
              <th v-if="wearhouse == 2 && invoice.table_view[11].value" id="idAutoComp">{{ $t('Beneficiary') }}</th>
              <th v-if="wearhouse == 2 && invoice.table_view[12].value" id="idAutoComp">{{ $t('Beneficiary Percentage') }}</th>
              <th v-if="wearhouse == 1 || invoice.table_view[13].value" id="idAutoComp">{{ $t('explain') }}</th>
              <th v-if="wearhouse == 2"></th>
            </tr>
            <v-fade-transition mode="out-in" :group="true" tag="tbody">
              <tr v-for="(material, ix) in invoice.materials" :key="ix">
                <td v-if="wearhouse == 1 || invoice.table_view[0].value">
                  <AutoCompleteField label="" v-model="material.material_id"
                    endPoint="/inventories/materials/auto-complete" returnObject @input="onMaterialInput(material)" />
                </td>
                <td v-if="wearhouse == 1 || invoice.table_view[1].value">
                  <v-autocomplete dense full-width hide-details flat :items="material.type" v-model="material.type_id"
                    item-text="name" item-value="id">
                  </v-autocomplete>
                </td>
                <td v-if="wearhouse == 1 || invoice.table_view[2].value">
                  <v-text-field full-width dense hide-details :readonly="(wearhouse == 1)" flat type="number"
                    color="#757575" @keydown.enter="ToNextInput" v-model.number="material.allquantity"></v-text-field>
                </td>
                <td v-if="type == 'ORDER' || wearhouse == 1">
                  <v-text-field full-width dense hide-details readonly type="number" flat color="#757575"
                    @keydown.enter="ToNextInput" v-model.number="material.deliveried"></v-text-field>
                </td>
                <td v-if="wearhouse == 1">
                  <v-text-field full-width dense hide-details type="number" flat color="#757575"
                    @keydown.enter="ToNextInput" v-model.number="material.quantity"></v-text-field>
                </td>
                <td v-if="wearhouse == 1 || invoice.table_view[3].value">{{ $t('Piece') }}</td>
                <td v-if="wearhouse == 2 && invoice.table_view[4].value ">
                  <v-text-field full-width dense hide-details type="number" flat color="#757575"
                    @keydown.enter="ToNextInput" v-model.number="material.price"></v-text-field>
                </td>
                <td v-if="wearhouse == 2 && invoice.table_view[5].value ">{{ material.allquantity * material.price }}</td>
                <td v-if="wearhouse == 2 && invoice.table_view[6].value ">
                  <v-text-field full-width dense hide-details type="number" flat color="#757575"
                    @keydown.enter="ToNextInput" v-model.number="material.discount_value"></v-text-field>
                </td>
                <td v-if="wearhouse == 2 && invoice.table_view[7].value ">
                  <v-text-field full-width dense hide-details type="number" flat color="#757575"
                    @keydown.enter="ToNextInput" v-model.number="material.increase_value"></v-text-field>
                </td>
                <td v-if="wearhouse == 2 && invoice.table_view[8].value ">
                  {{
                    material.allquantity * material.price -
                    material.discount_value +
                    material.increase_value
                  }}
                </td>
                <td v-if="wearhouse == 2 && invoice.table_view[14].value">
                    <v-autocomplete full-width dense hide-details flat :items="taxes" item-text="code" item-value="id"
                      v-model="material.tax_id"></v-autocomplete>
                  </td>
                  <td v-if="wearhouse == 2 && invoice.table_view[16].value">
                    <v-text-field full-width dense hide-details type="number" v-model="material.tax_percent"
                      @keydown.enter="ToNextInput" flat></v-text-field>
                  </td>
                  <td v-if="wearhouse == 2 && invoice.table_view[17].value" class="justify-center">
                    <v-checkbox :dark="$store.state.isDarkMode" v-model="material.is_tax_included" :true-value="1"
                      :false-value="0" class="mx-4 mb-4" hide-details></v-checkbox>
                  </td>
                  <td v-if="wearhouse == 2 && invoice.table_view[18].value"> {{ totalTaxI(material) }} </td>
                  <td v-if="wearhouse == 2 && invoice.table_view[15].value">
                    <AutoCompleteField label="" dense v-model="material.tax_account_id"
                      endPoint="/accounting/account/auto-complete" returnObject @input="ToNextInput" />
                  </td>
                <td v-if="wearhouse == 2 && invoice.table_view[9].value ">
                  <AutoCompleteField label="" v-model="material.inventory_id" endPoint="/inventories/auto-complete"
                    returnObject @input="ToNextInput" />
                </td>
                <td v-if="wearhouse == 2 && invoice.table_view[10].value ">
                  <AutoCompleteField label="" v-model="material.account_id" endPoint="/accounting/account/auto-complete"
                    returnObject @input="ToNextInput" />
                </td>
                <td v-if="wearhouse == 2 && invoice.table_view[11].value ">
                  <AutoCompleteField label="" v-model="material.beneficiary_account_id"
                    endPoint="/accounting/account/auto-complete" returnObject @input="ToNextInput" clearable />
                </td>
                <td v-if="wearhouse == 2 && invoice.table_view[12].value ">
                  <v-text-field full-width dense hide-details type="number" flat color="#757575"
                    @keydown.enter="ToNextInput" v-model.number="material.beneficiary_percentage"></v-text-field>
                </td>
                <td v-if="wearhouse == 1 || invoice.table_view[13].value">
                  <v-text-field full-width dense hide-details flat color="#757575" @keydown.enter="addNewMaterial"
                    v-model="material.desc"></v-text-field>
                </td>
                <td v-if="wearhouse == 2">
                  <v-icon @click="deleteRow(ix)" color="red"
                    style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>
                </td>
              </tr>
            </v-fade-transition>
          </v-simple-table>
        </v-container>
      </v-card>
      <v-col v-if="wearhouse == 2" md="9">
        <v-btn @click="newExpense" class="my-1" color="green" dark>{{ $t('Add Account') }}</v-btn>
        <v-row v-if="invoice.invoice_expenses.length">
          <v-col md="3"></v-col>
          <v-col md="3">
            <h3 style="text-align: center" class="mb-2">{{ $t('Expenses Account') }}</h3>
          </v-col>
          <v-col md="3">
            <h3 style="text-align: center" class="mb-2">{{ $t('Corresponding Account') }}</h3>
          </v-col>
          <v-col md="3">
            <h3 style="text-align: center" class="mb-2">{{ $t('Value') }}</h3>
          </v-col>
          <v-row class="py-0" v-for="(expense, ix) in invoice.invoice_expenses" :key="ix">
            <v-col md="3" class="pt-0">
              <v-btn color="red" dark @click="deleteInvoiceExpense(ix)">
                <v-icon size="20" dark>mdi-delete</v-icon> {{ $t('Delete Account') }}
              </v-btn>
            </v-col>
            <v-col md="3" class="py-0">
              <AutoCompleteField label="" v-model="expense.expense_account_id"
                endPoint="/accounting/account/auto-complete" outlined returnObject />
            </v-col>
            <v-col md="3" class="py-0">
              <AutoCompleteField label="" v-model="expense.related_account_id"
                endPoint="/accounting/account/auto-complete" outlined returnObject />
            </v-col>
            <v-col md="3" class="py-0">
              <v-text-field type="number" v-model.number="expense.value" outlined dense></v-text-field>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col md="3"></v-col>
          <v-col md="3"></v-col>
          <v-col md="3"></v-col>
          <v-col md="3" class="pl-0" v-if="invoice.invoice_expenses.length">
            <v-text-field :value="invoice.invoice_expenses.reduce(
              (prev, curr) => prev + curr.value,
              0
            )
              " readonly outlined dense :label="$t('Total Expenses')"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="wearhouse == 2" md="3" style="padding-top: 94px">
        <v-text-field :value="totalQuantities" readonly outlined dense :label="$t('Total Quantities')"></v-text-field>
        <v-text-field readonly :value="invoiceTotalValue()" outlined dense :label="$t('Net Invoice')" ></v-text-field>
        <v-text-field :value="taxTotalValue" readonly outlined dense :label="$t('Tax')"></v-text-field>
        <v-text-field :value="invoiceAfterTaxValue()" readonly outlined dense :label="$t('Invoice Value After Tax')"></v-text-field>
      </v-col>
      <v-col md="4">
        <v-btn color="blue" :disabled="!isNew && is_locked" block @click="createNewInvoice" :loading="submitting">{{
          wearhouse == 1 ?  $t('Receiving/Delivery') : isNew ? $t('add') : $t('edit') }}</v-btn>
      </v-col>
      <v-col v-if="!isNew" md="1">
        <v-btn color="green" @click="showAttachment">
          <v-icon class="white--text" size="20">mdi-paperclip</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog width="700" v-model="dialogAttachment">
      <v-card class="">
        <v-card-title class="justify-center">
          <h2>{{ }}</h2>
        </v-card-title>
        <v-card-text class="d-flex flex-column justify-center align-center">
          <div v-for="attachment in invoice.attachments" :key="attachment.id" class="add-user__chip1">
            <v-row style="font-weight: bold;">
              <v-col md="1"></v-col>
              <v-col md="4">{{ attachment.user.name }}</v-col>
              <v-col md="7">{{ attachment.date }}</v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <div class="cardd">
                  <div>
                    <v-row>
                      <v-col md="9">
                        <img class="preview-img" :src="attachment.path" />
                      </v-col>
                      <v-col md="3">
                        <v-icon v-if="returnAbility('invoice:delete:attachment')" color="red" @click="() => {
                          deleteAttachmentDialog = true;
                          attachmentId = attachment.id;
                        }
                          ">mdi-delete</v-icon>
                        <div class="mt-5">
                          <FileComponent :Id="attachment.id"></FileComponent>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div>
                    <v-row>
                      <v-col md="9">
                        <div class="test" :title="attachment.name">
                          <span>
                            {{ attachment.name }} -
                            {{ Math.round(attachment.size / 1000) + "kb" }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
          <app-attachment-modal path="invoiceOrder" @fetchData="showAttachment"></app-attachment-modal>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteAttachmentDialog" width="500">
      <v-card>
        <v-card-title class="d-flex justify-center">
          <h2>? {{ $t("are you sure delete ") }}</h2>
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn color="green" class="white--text" depressed @click="deleteAttachment(attachmentId)"
            :loading="deleteBtnLoading">{{ $t("submit") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row><v-col></v-col></v-row>
    <v-row><v-col></v-col></v-row>
    <v-row><v-col></v-col></v-row>
    <v-row><v-col></v-col></v-row>
    <Footer v-if="!isNew" path="invoiceOrder" class="d-flex justify-end" />
  </div>
</template>
<script>
import axios from "axios";
import AutoCompleteField from "../invoices/AutoCompleteField.vue";
import { saveAs } from 'file-saver';
import Footer from '../invoices/Footer.vue';
import attachmentModal from "../invoices/attachmentModal.vue";
import FileComponent from "../teachers/download.vue";
import i18n from "@/i18n";
export default {
  components: { AutoCompleteField, Footer, FileComponent, appAttachmentModal: attachmentModal, },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      wearhouse: this.$route.query.wear_house,
      dialogAttachment: false,
      deliveried: null,
      invoice_status: null,
      delivery_note_count: null,
      invoice_count: null,
      approve: false,
      installments: [],
      installmentdialog: false,
      is_locked: false,
      timeMenu: false,
      timeMenuRef: null,
      invoiceDateRequest: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      invoiceTimeRequest: `${String(new Date().getHours()).length == 1
        ? `0${new Date().getHours()}`
        : new Date().getHours()
        }:${String(new Date().getMinutes()).length == 1
          ? `0${new Date().getMinutes()}`
          : new Date().getMinutes()
        }`,
      invoiceDateOrder: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      invoiceTimeOrder: `${String(new Date().getHours()).length == 1
        ? `0${new Date().getHours()}`
        : new Date().getHours()
        }:${String(new Date().getMinutes()).length == 1
          ? `0${new Date().getMinutes()}`
          : new Date().getMinutes()
        }`,
      invoiceTypeLabel: null,
      fetchingMetadata: false,
      invoiceMetadata: null,
      disscountType: "PERCENTAGE",
      paymentMethods: [
        {
          label: i18n.t('Cash'),
          value: "CASH",
        },
        {
          label: i18n.t('Credit'),
          value: "CREDIT",
        },
        {
          label: i18n.t('Installment'),
          value: "INSTALLMENT",
        },
      ],
      dateMenu: false,
      attachmentId: null,
      invoice_type: null,
      invoice: {
        client_account_id: null,
        date: null,
        request_serial: null,
        order_serial: null,
        notes: null,
        discount_value: null,
        discount_type: null,
        invoice_type_id: this.$route.query.invoice_id,
        cost_center_id: null,
        inventory_id: null,
        payment_method: null,
        cash_account_id: null,
        discount_account_id: null,
        paid_amount: null,
        is_locked: false,
        invoice_expenses: [],
        materials: [],
        attachments: [],
        total_amount: 0,
        tax_id: null,
        tax_account_id: null,
        tax_percent: 0,
        table_view: [],
      },
      submitting: false,
      submittingPrint: false,
      submittingConvert: false,
      submittingApprove: false,
      deleteBtnLoading: false,
      deleteAttachmentDialog: false,
      type: null,
      taxes: [],
    };
  },
  computed: {
    totalQuantities() {
      let val = 0;
      if (this.invoice.materials.length) {
        val = this.invoice.materials.reduce((prev, curr) => prev + curr.allquantity, 0);
      }
      return val;
    },
    taxTotalValue() {
      let val = 0;
      if (this.invoice.materials.length) {
        this.invoice.materials.forEach(el => {
          if (el.tax_value) {
            val += el.tax_value;
          }
        });
      }
     if(val) return parseFloat(val.toFixed(2));
     else return 0;
    },
  },
  methods: {
    invoiceTotalValue() {
      let val = 0;
      if (this.invoice.materials.length) {
        this.invoice.materials.forEach(el => {
            val += (el.allquantity * el.price - el.discount_value) + el.increase_value;
        });
        if (
          this.invoice.discount_type === "FIXED" &&
          this.invoice.discount_value
        ) {
          val -= this.invoice.discount_value;
        } else if (
          this.invoice.discount_type === "PERCENTAGE" &&
          this.invoice.discount_value
        ) {
          val -= val * (this.invoice.discount_value / 100);
        }
      }
      return parseFloat(val.toFixed(2));
    },
    invoiceAfterTaxValue() {
      let val = 0;
      if (this.invoice.materials.length) {
        this.invoice.materials.forEach(el => {
            val += (el.allquantity * el.price - el.discount_value) + el.increase_value;
        });
        if (
          this.invoice.discount_type === "FIXED" &&
          this.invoice.discount_value
        ) {
          val -= this.invoice.discount_value;
        } else if (
          this.invoice.discount_type === "PERCENTAGE" &&
          this.invoice.discount_value
        ) {
          val -= val * (this.invoice.discount_value / 100);
        }
      }
      if (this.invoice.materials.length) {
        this.invoice.materials.forEach(el => {
          if (!el.is_tax_included) {
            if (el.tax_value) {
            val += el.tax_value;
          }
          }
        });
      }
      if(val) return parseFloat(val.toFixed(2));
     else return 0;
    },
    totalTaxI(mat) {
      let total = (mat.allquantity * mat.price) - mat.discount_value + mat.increase_value;
      if (mat.is_tax_included) {
        mat.tax_value = (total * mat.tax_percent) / (100 + parseFloat(mat.tax_percent));
      }
      else {
        mat.tax_value = total * mat.tax_percent / 100
      }
     if(mat.tax_value)  return parseFloat(mat.tax_value.toFixed(2));
     else return 0;
    },
    async deleteAttachment(id) {
      try {
        this.deleteBtnLoading = true;
        await axios.delete(`invoice/attachment/${id}`);
        this.deleteAttachmentDialog = false;
        this.$Notifications(
          "تم الحذف بنجاح",
          { timeout: 2000, rtl: true },
          "success"
        );
        this.showAttachment();
      } catch (err) {
      } finally {
        this.deleteBtnLoading = false;
      }
    },
    async showAttachment() {
      const res = await axios.get(`invoice/attachment/${this.$route.params.id}`)
      console.log(res);
      this.invoice.attachments = res.data.attachment;
      this.dialogAttachment = true;
    },
    deleteRowI(index) {
      if (this.installments.length == 1) return;
      this.installments.splice(index, 1);
    },
    addNewInstallment(focus = true) {
      this.installments.push({
        date: null,
        amount: null,
        note: null,

      });
      if (focus) {
        setTimeout(() => {
          this.ToNextInput();
        }, 0);
      }
    },
    deleteInvoiceExpense(ix) {
      this.invoice.invoice_expenses.splice(ix, 1);
    },
    async toggleInvoiceLock() {
      await axios.patch(
        `/inventories/invoices/${this.$route.params.id}/toggle-lock`,
        {
          is_locked: this.is_locked,
        }
      );
    },
    async getInvoiceTypeMetadata() {
      try {
        this.fetchingMetadata = true;
        let sisi = '';
        if (this.type == 'ORDER') sisi = 'order_serial'; else sisi = 'request_serial'
        const result = await axios.get(
          `/inventories/invoice-request/meta-data/${sisi}/${this.$route.query.invoice_id}`
        );
        this.invoiceMetadata = result.data.data;
        if (this.type == 'ORDER') { this.invoice.order_serial = this.invoiceMetadata.next_serial; }
        else { this.invoice.request_serial = this.invoiceMetadata.next_serial; }
          this.invoice.payment_method = this.invoiceMetadata.invoiceType.payment_method;
          this.invoice.cost_center_id = this.invoiceMetadata.invoiceType.cost_center;
          this.invoice.inventory_id = this.invoiceMetadata.invoiceType.inventory;
          this.invoice.tax_account_id = this.invoiceMetadata.invoiceType.tax_account;
          this.invoice.tax_percent = this.invoiceMetadata.invoiceType.tax_percent;
          this.invoice.tax_id = this.invoiceMetadata.invoiceType.tax_id;
          this.invoice.cash_account_id = this.invoiceMetadata.invoiceType.cash_account;
          this.invoice.discount_account_id = this.invoiceMetadata.invoiceType.discount_account;
          this.invoiceTypeLabel = this.invoiceMetadata.invoiceType.name;
          this.invoice.table_view = this.invoiceMetadata.invoiceType.table_view;
      } finally {
        this.fetchingMetadata = false;
      }
    },
    async getWearHouseTypeMetadata() {
      try {
        this.fetchingMetadata = true;
        const result = await axios.get(
          `/inventories/delivery-note/meta-data/${this.invoice_type}/${this.invoice.inventory_id.id}`
        );
        this.invoiceMetadata = result.data;
        this.invoice.request_serial = this.invoiceMetadata.next_serial;
      } finally {
        this.fetchingMetadata = false;
      }
    },
    matchMaterialInventoriesHandler() {
      this.invoice.materials.forEach((mat) => {
        mat.inventory_id = this.invoice.inventory_id;
      });
      if (this.wearhouse == 1) {
        this.getWearHouseTypeMetadata();
      }
    },
    matchMaterialTax_IdHandler() {
     this.taxes.forEach((el)=>{
      if(el.id == this.invoice.tax_id){
        this.invoice.tax_percent = el.percent;
        this.matchMaterialTax_PercentHandler();
      }
     });
      this.invoice.materials.forEach((mat) => {
        mat.tax_id = this.invoice.tax_id;
      });
    },
    matchMaterialTax_PercentHandler() {
      this.invoice.materials.forEach((mat) => {
        mat.tax_percent = this.invoice.tax_percent;
      });
    },
    matchMaterialTax_AccHandler() {
      this.invoice.materials.forEach((mat) => {
        mat.tax_account_id = this.invoice.tax_account_id;
      });
    },
    matchMaterialRelatedAccountHandler() {
      this.invoice.materials.forEach((mat) => {
        mat.account_id = this.invoiceMetadata.invoiceType.related_account;
      });
    },
    async convertInvoice() {
      this.submittingConvert = true;
      const res = await axios.put(`inventories/invoice-request/order/${this.$route.params.id}`);
      if (res.status == 200) {
        this.fetchData();
      }
      this.submittingConvert = false;
    },
    async convertToApprove() {
      this.submittingApprove = true
      const res = await axios.put(`inventories/invoice-request/approve/${this.$route.params.id}`);
      if (res.status == 200) {
        this.fetchData();
      }
      this.submittingApprove = false;
    },
    async printInvoice() {
      try {
        this.submittingPrint = true;
        const pdf = await axios.get(`inventories/invoices/pdf/${this.$route.params.id}`, {
          responseType: 'blob',
        });
        saveAs(pdf.data, "invoice.pdf");
      } catch (error) {
        console.error("Errore", error);
      }
      finally {
        this.submittingPrint = false;
      }
    },
    async createNewInvoice() {
      try {
        this.submitting = true;
        if (this.wearhouse == 1) {
          await axios.post("/inventories/delivery-note", {
            inventory_id: this.invoice.inventory_id?.id,
            date: `${this.invoiceDateRequest} ${this.invoiceTimeRequest}`,
            serial: this.invoice.request_serial,
            delivery_note_materials: this.invoice.materials.map((mat) => ({
              invoice_material_template_id: mat.id,
              quantity: mat.quantity,
              notes: mat.notes,
            })),
          });
        } else {
          let params = {
            ...this.invoice,
            tax_account_id: this.invoice.tax_account_id?.id,
            inventory_id: this.invoice.inventory_id?.id,
            request_date: `${this.invoiceDateRequest} ${this.invoiceTimeRequest}`,
            order_date: `${this.invoiceDateOrder} ${this.invoiceTimeOrder}`,
            cost_center_id: this.invoice.cost_center_id?.id,
            client_account_id: this.invoice.client_account_id?.id,
            discount_account_id: this.invoice.discount_account_id?.id,
            total_amount: this.invoiceTotalValue(),
            cash_account_id:
              this.invoice.payment_method == "CREDIT"
                ? null
                : this.invoice.cash_account_id?.id,
            paid_amount:
              this.invoice.payment_method == "CREDIT"
                ? null
                : this.invoice.paid_amount,
            materials: this.invoice.materials.map((mat) => ({
              ...mat,
              id: mat?.id,
              quantity: mat.allquantity,
              tax_account_id: mat.tax_account_id?.id,
              inventory_id: mat.inventory_id?.id,
              material_id: mat.material_id?.id,
              type: mat.material_id?.type,
              beneficiary_account_id: mat.beneficiary_account_id?.id,
              account_id: mat.account_id?.id,
            })),
            installments: this.installments.map((mat) => ({
              ...mat,
              date: mat.date,
              amount: mat.amount,
              note: mat.note,
            })),
            invoice_expenses: this.invoice.invoice_expenses.map((el) => ({
              expense_account_id: el.expense_account_id?.id,
              related_account_id: el.related_account_id?.id,
              value: el.value,
            })),
          }
          if (this.isNew) {
            await axios.post("/inventories/invoice-request", params);
            this.$Notifications( this.$t('add success'), { timeout: 2000, rtl: true },"success");
          } else {
            await axios.put(`/inventories/invoice-request/${this.$route.params.id}`, params);
            this.$Notifications( this.$t('edit success'), { timeout: 2000, rtl: true },"success");
          }
        }
        this.$router.go(-1);
      } catch (error) {
                console.log(error);
            }
      finally {
        this.submitting = false;
      }
    },
    invoiceTypeFormatter(type) {
      return [
       {
        label: i18n.t('purchase'),
        value: "PURCHASES",
      },
      {
        label: i18n.t('sales'),
        value: "SALES",
      },
      {
        label: i18n.t('purchase refunds'),
        value: "PURCHASE_REFUNDS",
      },
      {
        label: i18n.t('sales refunds'),
        value: "SALES_REFUNDS",
      },
      ].find((el) => el.value == type).label;
    },
    newExpense() {
      this.invoice.invoice_expenses.push({
        expense_account_id: null,
        related_account_id: null,
        value: null,
      });
    },
    Installmentdistribution() {
      this.installments.push({
        date: null,
        amount: null,
        note: null,
      });
    },
    deleteRow(index) {
      if (this.invoice.materials.length == 1) return;
      this.invoice.materials.splice(index, 1);
    },
    async onMaterialInput(material) {
      this.ToNextInput();
      const result = await axios.get(
        `/inventories/materials/${material.material_id.id}?invoice_type=${this.$route.query.invoice_id}`
      );
      const resultMetadata = await axios.get(`/inventories/invoices/invoice-type/${this.$route.query.invoice_id}`);
      const metadataTemp = resultMetadata.data.data.invoiceType;
      const dataTemp = result.data.data;
      const invoiceType = resultMetadata.data.data.invoiceType.type;
      if (invoiceType == "PURCHASES") {
        if (dataTemp.purchases_account) {
          material.account_id = dataTemp.purchases_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      } else if (invoiceType == "SALES") {
        if (dataTemp.sales_account) {
          material.account_id = dataTemp.sales_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      } else if (invoiceType == "PURCHASE_REFUNDS") {
        if (dataTemp.purchases_refund_account) {
          material.account_id = dataTemp.purchases_refund_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      }
      else {
        if (dataTemp.sales_account) {
          material.account_id = dataTemp.sales_account;
        } else {
          material.account_id = metadataTemp.related_account;
        }
      }
      material.type = dataTemp.values;
      material.price = dataTemp.price;
      material.deliveried = dataTemp.deliveried;
      if ((invoiceType == "PURCHASE_REFUNDS" || invoiceType == "SALES") && dataTemp.input_tax_id && this.invoice.tax_account_id != null) {
        material.tax_id = dataTemp.input_tax_id;
        material.tax_percent = dataTemp.tax_percent;
      }
      if ((invoiceType == "PURCHASE" || invoiceType == "SALES_REFUNDS") && dataTemp.output_tax_id && this.invoice.tax_account_id != null) {
        material.tax_id = dataTemp.output_tax_id;
        material.tax_percent = dataTemp.tax_percent;
      }
    },
    addNewMaterial(focus = true) {
      if (this.wearhouse == 2) {
        let acc;
      this.isNew ? acc = this.invoiceMetadata.invoiceType.related_account : acc = this.invoice.materials[0].account_id;
        this.invoice.materials.push({
          material_id: null,
          type: null,
          type_id: null,
          quantity: 0,
          allquantity: 0,
          price: 0,
          deliveried: null,
          discount_value: 0,
          increase_value: 0,
          account_id: acc,
          beneficiary_account_id: null,
          beneficiary_percentage: null,
          desc: null,
          inventory_id: this.invoice.inventory_id,
          tax_account_id: this.invoice.tax_account_id,
          tax_id: this.invoice.tax_id,
          tax_percent: this.invoice.tax_percent,
          tax_value: 0,
          is_tax_included: 0,
        });
        if (focus) {
          setTimeout(() => {
            this.ToNextInput();
          }, 0);
        }
      }
    },
    ToNextInput() {
      const inputs = Array.prototype.slice.call(
        document.querySelectorAll("table input:not([type=hidden])"));
      const currInput = document.activeElement;
      const currInputIndex = inputs.indexOf(currInput);
      const nextinputIndex = currInputIndex + 1;
      const input = inputs[nextinputIndex];
      input.focus();
    },
    async fetchData() {
      let sisi = '';
      if (this.wearhouse == 1) {
        sisi = '/inventories/delivery-note/order';
      } else {
        sisi = '/inventories/invoice-request';
      }
      const result = await axios.get(`${sisi}/${this.$route.params.id}`);
      const dataTemp = result.data.data;
      if (this.wearhouse == 1) {
        this.invoice_type = dataTemp.invoice_type.type;
        this.invoice.client_account_id = dataTemp.client_account;
        this.invoice.materials = dataTemp.invoice_materials.map((el) => ({
          ...el,
          allquantity: el.quantity,
          quantity: el.quantity - el.deliveried,
          material_id: el.material,
          inventory_id: el.inventory,
          account_id: el.account,
          beneficiary_account_id: el.beneficiary_account,
        }));
        this.invoice.inventory_id = dataTemp.inventory;
        this.getWearHouseTypeMetadata();
        this.invoice.notes = dataTemp.notes;
        this.invoiceTypeLabel = dataTemp.invoice_type.name;
      } else {
        const dateSplitR = dataTemp.request_date.split(" ");
        this.invoice_type = dataTemp.invoice_type.type;
        this.is_locked = dataTemp.is_locked;
        this.type = dataTemp.type;
        this.approve = dataTemp.approve;
        this.invoice_status = dataTemp.invoice_status;
        this.deliveried = dataTemp.deliveried;
        this.delivery_note_count = dataTemp.delivery_note_count;
        this.invoice_count = dataTemp.invoice_count;
        this.invoiceDateRequest = dateSplitR[0];
        this.invoiceTimeRequest = dateSplitR[1];
        if (this.type == "ORDER") {
          const dateSplitO = dataTemp.order_date.split(" ");
          this.invoiceDateOrder = dateSplitO[0];
          this.invoiceTimeOrder = dateSplitO[1];
        }
        this.invoice.invoice_expenses = dataTemp.invoice_expenses.map((el) => ({
          expense_account_id: el.expense_account,
          related_account_id: el.related_account,
          value: el.value,
        }));
        this.invoice.materials = dataTemp.invoice_materials.map((el) => ({
          ...el,
          allquantity: el.quantity,
          material_id: el.material,
          inventory_id: el.inventory,
          account_id: el.account,
          beneficiary_account_id: el.beneficiary_account,
        }));
        this.installments = dataTemp.installments.map((el) => ({
          ...el,
          date: el.date,
          amount: el.amount,
          note: el.note,
        }));
        this.invoice.discount_type = dataTemp.discount_type;
        this.invoice.discount_value = dataTemp.discount_value;
        this.invoice.request_serial = dataTemp.request_serial;
        this.invoice.order_serial = dataTemp.order_serial;
        this.invoice.notes = dataTemp.notes;
        this.invoice.paid_amount = dataTemp.paid_amount;
        this.invoice.payment_method = dataTemp.payment_method;
        this.invoice.cost_center_id = dataTemp.cost_center;
        this.invoice.inventory_id = dataTemp.inventory;
        this.invoice.cash_account_id = dataTemp.cash_account;
        this.invoice.discount_account_id = dataTemp.discount_account;
        this.invoice.client_account_id = dataTemp.client_account;
        this.invoiceTypeLabel = dataTemp.invoice_type.name;
        this.invoice.tax_account_id = dataTemp.invoice_type.tax_account;
        this.invoice.tax_percent = dataTemp.invoice_type.tax_percent;
        this.invoice.tax_id = dataTemp.invoice_type.tax_id;
        this.invoice.table_view = dataTemp.invoice_type.table_view;
      }
    }
  },
  async created() {
    if (this.isNew) {
      await this.getInvoiceTypeMetadata();
      this.addNewMaterial(false);
    }
    else {
      this.fetchData();
    }
    this.addNewInstallment(false);
    let params = { paginated: 'false', };
    if(this.returnAbility('tax:index')){
        let res = await axios.get(`/accounting/tax`, { params });
        this.taxes = res.data.data;
      }
  },
};
</script>
<style scoped lang="scss">
.add-user__chip1 {
  border: 1px solid #757575;
  border-radius: 10px;
  margin: 5px 0;
  width: 100%;
}

.cardd {
  border: 1px solid #a2a2a2;
  width: 100%;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 4px 4px;
}

.cardd .preview-img {
  width: 100%;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
}

.tableId {
  border-radius: 10%;
}

td,
th {
  padding: 0px 10px;
  border-collapse: collapse;
  border-radius: 10%;
}

table,
th,
td {
  border: 1px solid #000;
  border-collapse: collapse;
  border-radius: 10%;
}

#idAutoComp {
  padding-left: 70px;
  padding-right: 70px;
}

#idPrice {
  padding-left: 40px;
  padding-right: 40px;
}

#buffet-table {
  border: 1px solid rgb(216 216 221 / 53%);
  font-size: 1rem;
}

#buffet-table th {
  color: #909399;
  border: 1px solid rgb(216 216 221 / 53%);
  font-size: 1rem;
  text-align: center;
  width: max-content;

}

#buffet-table tr {
  transition: 0.5s;
}

#buffet-table td {
  border: 1px solid rgb(216 216 221 / 53%);
  text-align: center;
  font-size: 1.1rem;
  width: max-content;
}</style>
